import styled from '@emotion/styled';
import { theme } from './layout/GlobalStyles';

interface ComponentProps {
    border?: boolean;
    color: 'yellow' | 'red' | 'black';
    position: 'left' | 'center';
    small?: boolean;
    sub?: boolean;
}

export const TitleStyled = styled.h2<ComponentProps>`
    font-family: 'fraunces';
    font-size: 24px;
    font-weight: 600;
    z-index: 1;
    position: relative;
    line-height: 30px;
    left: ${({ position }) => (position === 'left' ? '0px' : '17px')};
    letter-spacing: 0em;
    margin-bottom: ${({ sub }) => (sub ? `1em` : `1.75em`)};
    padding-left: ${({ border }) => (border ? `16px` : `0px`)};
    color: ${({ color }) =>
        (color === 'yellow' && `${theme.colors.yellow1}`) ||
        (color === 'red' && `${theme.colors.red1}`) ||
        (color === 'black' && `${theme.colors.gray3}`)};
    &::before {
        content: '';
        display: ${({ border }) => (border ? `block` : `none`)};
        width: 4px;
        min-height: 35px;
        height: auto;
        position: relative;
        left: -16px;
        bottom: -32px;
        background-color: ${({ color }) =>
            (color === 'yellow' && `${theme.colors.yellow1}`) ||
            (color === 'black' && `${theme.colors.gray3}`) ||
            (color === 'red' && `${theme.colors.red1}`)};
    }
    @media ${theme.device.laptop} {
        justify-content: space-between;
        font-size: ${({ small }) => (small ? `24px` : `36px`)};
        line-height: 44px;
        left: 0;
        display: flex;
        max-width: 540px;
        //make the width just as wide as the text
        width: fit-content;
        justify-content: ${({ position }) => (position === 'left' ? 'flex-start' : 'center')};
        &::before {
            bottom: 0px;
            left: -16px;
        }
    }
    @media ${theme.device.desktop} {
        font-size: 42px;
    }
`;

interface Props extends ComponentProps {
    children: React.ReactNode;
    className?: string;
}

export const Title: React.FC<Props> = props => {
    const { border, children, color, className, position, small, sub } = props;
    return (
        <TitleStyled
            className={className}
            color={color}
            border={border}
            position={position}
            small={small}
            sub={sub}
        >
            {children}
        </TitleStyled>
    );
};
