import { css, Global, Theme } from '@emotion/react';
import emotionNormalize from 'emotion-normalize';

type Size = {
    mobileS: string;
    mobileM: string;
    mobileL: string;
    tablet: string;
    tabletL: string;
    laptop: string;
    laptopL: string;
    desktop: string;
    desktopL: string;
};

const size: Size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '400px',
    tablet: '650px',
    tabletL: '690px',
    laptop: '1070px',
    laptopL: '1280px',
    desktop: '1580px',
    desktopL: '1820px',
};

export const theme: Theme = {
    colors: {
        gray1: '#4B5049',
        gray2: '#323531',
        gray3: 'rgb(18, 12, 8)',
        black: '#0C0D0C',
        red1: '#CD5740',
        red2: '#A53C22',
        yellow1: '#E4C96F',
        offWhite: '#B1B6AF',
        white: '#F2F3F2',
    },
    device: {
        mobileS: `(min-width: ${size.mobileS})`,
        mobileM: `(min-width: ${size.mobileM})`,
        mobileL: `(min-width: ${size.mobileL})`,
        tablet: `(min-width: ${size.tablet})`,
        tabletL: `(min-width: ${size.tabletL})`,
        laptopS: `(min-width: ${size.tablet}) and (orientation:landscape)`,
        laptop: `(min-width: ${size.laptop}) and (orientation:landscape)`,
        laptopL: `(min-width: ${size.laptopL})`,
        desktop: `(min-width: ${size.desktop})`,
        desktopL: `(min-width: ${size.desktopL})`,
    },
};

export const GlobalStyles = () => {
    return (
        <Global
            styles={() => css`
                ${emotionNormalize};
                html,
                body {
                    scroll-behavior: smooth;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    overflow-x: hidden;
                }
                body {
                    font-weight: normal;
                    font-size: 1rem;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    word-wrap: break-word;
                    margin: 0;
                    font-family: 'poppins';
                    -webkit-font-smoothing: antialiased;
                }
                * {
                    box-sizing: border-box;
                }
                a {
                    text-decoration: none;
                    color: ${theme.colors.red2};
                }

                .container,
                .large-container {
                    padding: 0px 16px;
                }

                ul {
                    padding: 0;
                }
                h1,
                h2,
                h3 {
                    font-family: 'fraunces';
                }
                h3 {
                    color: ${theme.colors.white};
                    font-size: 26px;
                    padding-top: 16px;
                    padding-bottom: 16px;
                }
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                ul,
                li,
                p,
                button,
                div {
                    margin: 0;
                }

                p {
                    font-weight: 400;
                    margin-bottom: 1em;
                }
                button {
                    border: none;
                    outline: none;
                    background: none;
                }

                p,
                li {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 152.5%;
                    color: ${theme.colors.white};
                }

                @media ${theme.device.mobileL} {
                    .container,
                    .large-container {
                        padding: 0px 16px;
                    }
                }
                @media ${theme.device.tablet} {
                    .header-2 {
                        font-weight: 700;
                        font-size: 36px;
                        line-height: 122.5%;
                    }
                }
                @media ${theme.device.laptop} {
                    .container {
                        max-width: 980px;
                    }

                    .large-container {
                        max-width: 1020px;
                    }
                }
                @media ${theme.device.laptopL} {
                    .container {
                        max-width: 1104px;
                        margin: 0 auto;
                    }
                    .large-container {
                        max-width: 1280px;
                    }
                }
                @media ${theme.device.desktop} {
                    .container {
                        max-width: 1280px;
                    }
                }
                @media ${theme.device.desktopL} {
                    .container {
                        margin: 0 auto;
                    }
                }
            `}
        />
    );
};
