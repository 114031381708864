import React from 'react';
import styled from '@emotion/styled';
import { theme } from './layout/GlobalStyles';
const StyledHours = styled.div`
    display: flex;
    gap: 4em;
    ul {
        padding: 0;
        list-style: none;
        li {
            margin-bottom: 1em;
        }
        span {
            opacity: 0.4;
        }
    }
    ul:first-of-type {
        li {
            font-family: 'fraunces';
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    ul:last-of-type {
        li {
            margin-bottom: 1.8em;
            position: relative;
            top: 0.4em;
        }
    }
    @media ${theme.device.laptop} {
    }
`;

interface ComponentProps {
    children: React.ReactNode;
}

export const Hours: React.FunctionComponent<ComponentProps> = ({ children }) => {
    return <StyledHours>{children}</StyledHours>;
};
