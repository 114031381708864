import React from 'react';
import styled from '@emotion/styled';
import { theme } from './layout/GlobalStyles';

interface StyledProps {
    gap: number;
    reverse?: boolean;
    equal?: boolean;
    center?: boolean;
    width?: string;
}

const StyledFlex = styled.div<StyledProps>`
    display: flex;
    align-items: center;
    flex-direction: column;
    @media ${theme.device.laptop} {
        max-width: ${({ width }) =>
            (width === 'narrow' && '1400px') || (width === 'wide' && '100%') || '1720px'};
        margin: 0 auto;
        align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
        gap: ${({ gap }) => `${gap}px`};
        flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
        flex-wrap: nowrap;
        box-sizing: border-box;
        padding: 0;

        justify-content: space-between;
        > div {
            //this is key. if we don't wan't equal then things can shrink etc.
            //if they are equal then they have a flex-shink of 1; otherwise we set it manually
            flex-shrink: ${({ equal }) => equal && '1'};
            width: ${({ equal, gap }) => (equal ? `calc(50% - ${gap / 2}px)` : '100%')};
        }
    }
`;

interface ComponentProps extends StyledProps {
    children: React.ReactNode;
    className?: string;
}

export const Flex: React.FC<ComponentProps> = props => {
    const { children, gap, reverse, equal, center, width } = props;
    return (
        <StyledFlex width={width} reverse={reverse} equal={equal} center={center} gap={gap}>
            {children}
        </StyledFlex>
    );
};
