import styled from '@emotion/styled';
import { SVGProps } from 'react';

export interface IconProps extends SVGProps<SVGSVGElement> {
    name: string;
    width?: string | number;
    height?: string | number;
}

const StyledIcon = styled.svg`
    width: ${p => p.width ?? '32px'};
    height: ${p => p.height ?? '32px'};
    stroke: ${p => p.color};
`;

export const Icon: React.FC<IconProps> = ({ name, ...svgProps }) => {
    const icon = name.replace(/^.*?([^\/]+?)(?:\.[^\/]+)?$/, '$1');
    return (
        <StyledIcon {...svgProps}>
            <use xlinkHref={`/spritemap.svg#${icon}`}></use>
        </StyledIcon>
    );
};
