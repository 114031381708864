import React, { useState } from 'react';
import styled from '@emotion/styled';
import { ButtonPrimary } from './StyledComponents';
import { theme } from './GlobalStyles';
import { Icon } from '@/Icon';

export const ContactFormStyled = styled.section`
    overflow: hidden;
    @media ${theme.device.tablet} {
        overflow: visible;
    }
`;

const Form = styled.form`
    padding: 2px;
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        position: absolute;
        top: 0;
        opacity: 0;
        -webkit-appearance: none;
    }
    #message {
    }
    input,
    textarea {
        font-weight: 500;
        border: none;
        width: 100%;
        outline: 2px fill ${theme.colors.gray2};
        padding: 15px 24px 15px 12px;
        transition: 0.2s ease-in;
        border-radius: 16px;
        margin-bottom: 2em;
    }

    button {
        padding: 1em 3.2em;
    }
    .input-container {
        position: relative;

        input {
            border-radius: 16px;
            padding-left: 42px;
        }

        svg {
            fill: ${theme.colors.gray3};
            width: 24px;
            left: 12px;
            top: 10%;
            position: absolute;
        }

        input[type='date']::-webkit-calendar-picker-indicator {
            width: 100%;
        }
    }
    .flex {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > div {
            width: 46%;
        }
    }
    input:focus,
    textarea:focus {
        border: none;
        outline: 2px fill ${theme.colors.red1};
    }

    textarea {
        margin-bottom: 3.2em;
        height: 100px;
    }

    input::placeholder {
        color: ${theme.colors.gray3};
    }
    textarea::placeholder {
        line-height: 24px;
    }

    ::-webkit-input-placeholder {
        color: ${theme.colors.gray3};
        opacity: 0.54;
    }
    @media ${theme.device.tablet} {
    }
    @media ${theme.device.laptop} {
        display: flex;
        flex-direction: column;
        .flex {
            gap: 32px;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }
`;

export const ContactForm: React.FC = () => {
    const [submitState, setSubmitState] = useState<string>();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSubmit = async (e: any) => {
        e.preventDefault();
        setSubmitState('submitting');

        // important way for us to validate the form information and create a new object.
        //note the refuce function pushing the keys and values into the empty object dependency

        const formData = new FormData(e.target);
        const data = [...formData.keys()].reduce(
            (obj, key) => {
                const value = formData.getAll(key).join(', ').trim();
                if (value) {
                    obj[key] = value;
                }
                return obj;
            },
            {} as Record<string, unknown>
        );

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        setSubmitState('submitted');

        e.target.reset();
    };
    return (
        <>
            <div style={{ position: 'relative', top: '-300px' }} id="contact"></div>
            <ContactFormStyled>
                <Form onSubmit={onSubmit}>
                    <div className="input-container">
                        <Icon name="avatar" />
                        <input placeholder="your name" required id="name" name="name" type="text" />
                    </div>
                    <div className="input-container">
                        <Icon name="mail" />
                        <input
                            placeholder="jane.doe@gmail.com"
                            required
                            id="email"
                            name="email"
                            type="text"
                        />
                    </div>
                    <div className="flex">
                        <div className="input-container">
                            <Icon name="call" />
                            <input
                                placeholder="123-456-7891"
                                required
                                id="phone"
                                name="phone"
                                type="text"
                            />
                        </div>
                        <div className="input-container">
                            <Icon name="calendar" />
                            <input
                                type="date"
                                name="date"
                                id="date"
                                placeholder="YYYY-MM-DD"
                                required
                            />
                        </div>
                    </div>
                    <textarea
                        id="message"
                        name="message"
                        placeholder="ADD ANY COMMENTS FOR OUR STAFF OR DOCTORS"
                    />
                    <div className="button-container">
                        <ButtonPrimary
                            type="submit"
                            disabled={submitState === 'Submitting'}
                            color="orange"
                            solid
                        >
                            {submitState === 'SUBMITTING'
                                ? 'SENDING MESSAGE...'
                                : 'SCHEDULE APPOINTMENT'}
                        </ButtonPrimary>
                    </div>
                </Form>
            </ContactFormStyled>
        </>
    );
};
