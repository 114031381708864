import React from 'react';
import styled from '@emotion/styled';
import { theme } from './GlobalStyles';
import { StaticImage } from 'gatsby-plugin-image';

const StyledFooter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    > div {
        align-items: start;
    }
    a {
        color: ${theme.colors.yellow1};
    }
    h2 {
        border-bottom: ${({ theme }) => `2px fill ${theme.colors.red1}`};
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
    }
    .logo {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        position: relative;
        right: 10px;
        width: 75%;
    }
    .red-border {
        width: 75vw;
        height: 2px;
        background-color: ${theme.colors.red1};
        margin: 48px auto;
    }
    ul {
        margin-bottom: 48px;
        padding: 0;
        width: 100%;
        list-style: none;
        li {
            font-family: 'poppins';
            font-size: 16px;
            margin-bottom: 1em;
            font-weight: 500;
            line-height: 162%;
            letter-spacing: 1.92px;
            text-transform: uppercase;
            color: ${theme.colors.yellow1};
        }
    }
    @media ${theme.device.tablet} {
        > div {
            flex-direction: row;
            justify-content: space-between;
            width: 92%;
            max-width: 1400px;
            align-items: stretch;
        }
        .logo {
            position: relative;
            right: 32px;
            width: 30%;
            margin: 0 auto;
        }
    }
`;
interface ComponentProps {
    children: React.ReactNode;
}

export const Footer: React.FunctionComponent<ComponentProps> = ({ children }) => {
    return (
        <StyledFooter>
            <StaticImage
                className="logo"
                src="../../images/common/logo.png"
                alt="maiden family dentistry"
                placeholder="none"
                quality={100}
            />
            <div className="red-border"></div>
            {children}
        </StyledFooter>
    );
};
