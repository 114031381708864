import React from 'react';
import styled from '@emotion/styled';
import { theme } from './GlobalStyles';

const StyledMargin = styled.div`
    margin-bottom: 64px;
    @media ${theme.device.laptop} {
        margin-bottom: 0px;
    }
`;

export const Margin: React.FunctionComponent = () => {
    return <StyledMargin></StyledMargin>;
};
