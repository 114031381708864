export const navMenu = [
    {
        name: 'About Us',
        path: '',
        menu: [
            { name: 'Our Practice', path: '/about-us/our-practice/' },
            { name: 'Meet Our Staff', path: '/about-us/meet-our-staff/' },
        ],
    },
    {
        name: 'Services',
        path: '',
        menu: [
            {
                name: 'Veneers',
                path: '/services/cosmetic-dentistry/veneers/',
            },
            {
                name: 'Teeth Whitening',
                path: '/services/cosmetic-dentistry/teeth-whitening/',
            },
            {
                name: 'Dental Cleanings',
                path: '/services/general-dentistry/dental-cleanings/',
            },
            {
                name: 'Root Canal',
                path: '/services/general-dentistry/root-canal/',
            },
            {
                name: 'Wisdom Tooth Extraction',
                path: '/services/general-dentistry/wisdom-tooth-extraction/',
            },
            {
                name: 'General Tooth Extractions',
                path: '/services/general-dentistry/general-tooth-extraction/',
            },
            {
                name: 'Dental Crowns',
                path: '/services/restorative-dentistry/dental-crowns/',
            },
            {
                name: 'Implant Restoration',
                path: '/services/restorative-dentistry/implant-restoration/',
            },
            {
                name: 'Periodontal Care',
                path: '/services/restorative-dentistry/periodontal-care/',
            },
            {
                name: 'Partial Dentures',
                path: '/services/dentures/partial-dentures/',
            },
            {
                name: 'Mouth Guards',
                path: '/services/dentures/mouth-guards/',
            },
            {
                name: 'Flexible Based Dentures',
                path: '/services/dentures/flexible-base-dentures/',
            },
        ],
    },
    {
        name: 'Patient Resources',
        path: '',
        menu: [
            {
                name: 'FAQ',
                path: '/patient-resources/faq/',
            },
            { name: 'Financing Options', path: '/patient-resources/financing-options/' },
            { name: 'Privacy Policy', path: '/patient-resources/privacy-policy/' },
            // { name: 'Blog', path: '/blog/' },
        ],
    },
];
